import React, { useRef } from "react"
import { StaticImage } from "gatsby-plugin-image"
import { navigate } from "gatsby"

import Layout from "../../../components/layout"
import Seo from "../../../components/seo"
import "./style.scss"
import ContactModal from "../../../components/contactModal"

import WhiteBg from "/static/cases/walmart/白色底图.png"
import MainImg from "/static/cases/walmart/成功案例_1.png"
import SolutionIcon1 from "/static/cases/walmart/01_某跨国零售_icon1.png"
import SolutionIcon2 from "/static/cases/walmart/01_某跨国零售_icon2.png"
import SolutionIcon3 from "/static/cases/walmart/01_某跨国零售_icon3.png"
import SolutionIcon4 from "/static/cases/walmart/01_某跨国零售_icon4.png"
import SolutionIcon5 from "/static/cases/walmart/01_某跨国零售_icon5.png"

const Zmm = () => {
  const modalRef = useRef()
  return (
    <Layout>
      <ContactModal ref={modalRef} />
      <Seo
        title="某跨国零售巨头智能拼柜项目_装箱管理软件-蓝胖智汇"
        keywords="装满满；装柜软件；装箱软件；在线装柜；集装箱装柜；集装箱装箱；智能拼柜"
        description="蓝胖智汇专注自研装箱管理软件、智能装箱码垛系统，为客户提供仓储物流场景的多项智化升级服务，帮助客户提高供应链决策效率，降低企业管理成本。"
      />
      <div className="relative">
        <StaticImage
          src="../case-banner.png"
          alt="跨国零售巨头智能拼柜项目_装箱管理软件-蓝胖智汇"
          className="w-full"
        />
        <div className="absolute top-28 left-24 text-white text-3xl">
          成功案例
        </div>
      </div>
      <div className="walmart-container">
        <div id="lead">
          <img src={WhiteBg} alt="地图" />
          <div className="giant">
            <h1 className="inline-block">某跨国零售巨头</h1>{" "}
            <span>跨国企业</span>
            <span>海外地区</span>
            <span>零售业</span>
          </div>
          <div className="services">
            提供的产品与服务：装满满、企业定制化服务、提供智能拼柜解决方案
          </div>
        </div>
        <div id="project-background">
          <div className="left">
            <div className="title">项目背景</div>
            <div className="para">
              某跨国零售巨头成立于1962年，作为世界上最大的零售企业，全球共计开立门店超过8500家，并且常年屹立于世界500强企业名单前列。多年的高效经营离不开该企业强大的信息网络联通以及供应链能力，日常经营覆盖约8万多种商品。同时，为满足全球多家连锁店的配送需要，日运货量可超百万个集装箱，每年货运总行程可达65000万公里。
            </div>
            <div className="para">
              中国作为该企业的最重要的商品生产地和集散地，每天都有大量商品从中国各地集散运出。其中，该企业的第三方物流服务商负责将其华南地区数百个工厂的货物统一运送至盐田港完成集拼，再通过海运方式发送至北美东、西目的港。蓝胖子与该第三方合同物流服务商深度合作，为其提供智能拼柜解决方案，高效解决针对其大型海运集拼场景的集装箱拼箱难题。
            </div>
          </div>
          <img src={MainImg} alt="零售商超" />
        </div>
      </div>
      <div id="pain-point">
        <div className="content">
          <div className="title">客户痛点</div>
          <div className="points">
            <div className="point point1">
              货量大，货品SKU种类繁多，拼载规则复杂且周期性发生调整，挑战人工拼柜规划能力；
            </div>
            <div className="point point2">
              集装箱装载率要求高，进一步增加人员培育管理成本；
            </div>
            <div className="point point3">
              手工通过数据表筛选拼柜，低效且易出错，增加合规管理成本；
            </div>
            <div className="point point4">
              缺乏信息协同，亟待智能化工具帮助降本增效。
            </div>
          </div>
        </div>
      </div>
      <div id="solutions">
        <div className="title">解决方案</div>
        <div className="about">
          提供针对零售业务大型海运集拼场景提供智能拼柜解决方案，以SaaS平台实现。从2020年9月到2021年8月底，通过使用智能拼柜解决方案完成了超过44万立方米的货物，近7000个货柜的规划计算，所有装载规划方案都成功实际指导仓库装箱。满足用户以下需求：
        </div>
        <div className="cards">
          <div className="card">
            <div className="card-content">
              <img src={SolutionIcon1} alt="icon" />
              <div className="text">
                相同订单或目的港货物同一位置摆放提升装卸货效率
              </div>
            </div>
          </div>
          <div className="card">
            <div className="card-content">
              <img src={SolutionIcon2} alt="icon" />
              <div className="text">危险物品安全合规拼柜</div>
            </div>
          </div>
          <div className="card">
            <div className="card-content">
              <img src={SolutionIcon3} alt="icon" />
              <div className="text">根据订单货量推荐最优柜型组合</div>
            </div>
          </div>
          <div className="card">
            <div className="card-content">
              <img src={SolutionIcon4} alt="icon" />
              <div className="text">根据需求选择指定货物完成打托</div>
            </div>
          </div>
          <div className="card">
            <div className="card-content">
              <img src={SolutionIcon5} alt="icon" />
              <div className="text">保证单次货物拼柜规划最高装箱率</div>
            </div>
          </div>
        </div>
      </div>
      <div id="benefits">
        <div className="content">
          <div className="title">项目效益</div>
          <div className="cards">
            <div className="card">
              <div className="head">88% - 90%</div>
              <div className="desc">货柜平均装载率</div>
            </div>
            <div className="card">
              <div className="head">8分钟</div>
              <div className="desc">平均方案规划时间</div>
            </div>
            <div className="card">
              <div className="head">90%</div>
              <div className="desc">人力成本节约</div>
            </div>
            <div className="card">
              <div className="head">2-3倍</div>
              <div className="desc">综合作业效率提升</div>
            </div>
            <div className="card">
              <div className="head">运用仓库实际作业</div>
              <div className="desc">高可靠性</div>
            </div>
            <div className="card">
              <div className="head">3000万+</div>
              <div className="desc">年度可节约海运集装箱费用</div>
            </div>
          </div>
        </div>
      </div>
      <div id="misc">
        <div className="content">
          <div className="qa">
            <div className="title">其他问题</div>
            <div className="detail">
              告诉我们您需要解决什么问题，专家会帮助您找到最适合您的解决方案
            </div>
            <button
              onClick={() => modalRef.current.openModal()}
              className="contact-us-now"
              gtm-id="cases-contact-us"
            >
              联系我们
            </button>
          </div>
          <div className="try">
            <div className="product">
              <div className="about">开始体验 装满满-精简版</div>
              <div
                className="go check-zmm-lite"
                onClick={() =>
                  window.open("https://zmm-lite.doraopt.com?ref=h")
                }
              >
                立即体验
              </div>
            </div>
            <div className="product">
              <div className="about">更多产品</div>
              <div
                className="go check-products"
                onClick={() => navigate("/products")}
              >
                查看更多产品
              </div>
            </div>
            <div className="product">
              <div className="about">继续浏览</div>
              <div
                className="go check-cases"
                onClick={() => navigate("/cases")}
              >
                查看更多成功案例
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default Zmm
